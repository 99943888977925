import React, { useRef, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { FORM_ERROR, FormApi } from 'final-form';

import { Dialog } from 'components/common';
import { Input, Select } from 'components/form';
import { required, minLength, maxLength, composeValidators } from 'components/form/validationRules';

interface Props {
  open: boolean;
  onCancel: () => void;
  user: Rollo.UserDetailsDTO;
}

interface FormValues {
  fraudType: string;
  reason: string;
  dimensions?: string;
  email?: string;
  fromAddress?: string;
  toAddress?: string;
  ipAddress?: string;
  orderId?: string;
  paymentIntent?: string;
  phone?: string;
  weight?: string;
}

const defaultValues: FormValues = {
  fraudType: '',
  reason: '',
};

const fraudTypesOptions = [
  { label: 'MANUAL', value: 'MANUAL' },
  { label: 'PACKAGE_DIMENSION', value: 'PACKAGE_DIMENSION' },
  { label: 'PAYMENT_INTENT', value: 'PAYMENT_INTENT' },
  { label: 'PHONE_NUMBER', value: 'PHONE_NUMBER' },
  { label: 'IP_ADDRESS', value: 'IP_ADDRESS' },
  { label: 'DEBTOR', value: 'DEBTOR' },
];

export const EditFraudDetailsDialog = ({ open, onCancel, user }: Props) => {
  const dataProvider = useDataProvider();
  const formRef = useRef<FormApi<FormValues>>();

  const { fraudDescription } = user;

  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (formRef.current) {
      const defaultValues = {
        orderId: fraudDescription?.orderId,
        fraudType: user?.fraudReason,
        reason: fraudDescription?.reason,
        dimensions: fraudDescription?.dimensions,
        email: fraudDescription?.email || user?.email,
        fromAddress: fraudDescription?.fromAddress,
        toAddress: fraudDescription?.toAddress,
        ipAddress: fraudDescription?.ipAddress,
        paymentIntent: fraudDescription?.paymentIntent,
        phone: fraudDescription?.phone || user?.phone,
        weight: fraudDescription?.weight,
      };

      formRef.current.reset(defaultValues);
    }
  }, [fraudDescription, user]);

  const handleSubmitForm = async (values: FormValues) => {
    try {
      await dataProvider.update('fraudDetails', {
        id: user.id,
        data: values,
      });
      refresh();
      onCancel();
    } catch (error: any) {
      notify(error?.message);
      return { [FORM_ERROR]: error?.message };
    }
  };

  return (
    <Form<FormValues>
      initialValues={defaultValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, submitting, form }) => {
        formRef.current = form;

        return (
          <Dialog
            open={open}
            onCancel={onCancel}
            title="Edit fraud details"
            onConfirm={handleSubmit}
            submitting={submitting}>
            <Select
              name="fraudType"
              label="Fraud type"
              options={fraudTypesOptions}
              validate={required}
            />
            <Input
              name="reason"
              label="Reason"
              validate={composeValidators(required, minLength(5), maxLength(200))}
              inputProps={{
                multiline: true,
                rows: 3,
              }}
            />
            <Input name="dimensions" label="Dimensions" />
            <Input name="email" label="Email" />
            <Input name="fromAddress" label="From address" />
            <Input name="toAddress" label="To address" />
            <Input name="ipAddress" label="IP address" />
            <Input name="orderId" label="Order ID" />
            <Input name="paymentIntent" label="Payment intent" />
            <Input name="phone" label="Phone" />
            <Input name="weight" label="Weight" />
          </Dialog>
        );
      }}
    />
  );
};
