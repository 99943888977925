import React from 'react';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import { Moment } from 'moment';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { required } from 'components/form/validationRules';

import TypeChooser from './TypeChooser';
import { CreditCodeType } from '../types';
import DateInputField from './DateInputField';
import TimeInputField from './TimeInputField';

const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  date: {
    padding: theme.spacing(3, 0, 1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  input: {
    '& .MuiInput-input': {
      padding: theme.spacing(1, 0),
    },
  },
}));

interface Props {
  open: boolean;
  onCancel: () => void;
}

interface FormValues {
  type: CreditCodeType;
  name: string;
  numberOfCodes: string;
  priceOfCodes: string;
  redeemLimitOfCodes: string;
  startAtDate: { date: Moment | null; time: Moment | null };
  endAtDate: { date: Moment | null; time: Moment | null };
}

const defaultValues: FormValues = {
  type: CreditCodeType.Fund,
  name: '',
  numberOfCodes: '',
  priceOfCodes: '',
  redeemLimitOfCodes: '',
  startAtDate: {
    date: null,
    time: null,
  },
  endAtDate: {
    date: null,
    time: null,
  },
};

const getDate = ({ date, time }: { date: Moment | null; time: Moment | null }) => {
  if (!date || !time) {
    return;
  }

  return `${date.format('YYYY-MM-DD')}T${time.format('HH:mm:00.000Z')}`;
};

const CreateCampaignDialog = ({ open, onCancel }: Props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmitForm = async (values: FormValues) => {
    try {
      const requestData = {
        ...values,
        startAtDate: getDate(values.startAtDate),
        endAtDate: getDate(values.endAtDate),
      };

      const response = await dataProvider.post('creditCodes', { data: requestData });

      onCancel();

      refresh();

      return response;
    } catch (error: any) {
      notify(error?.message);
    }
  };

  return (
    <Form<FormValues>
      initialValues={defaultValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <Dialog
            open={open}
            onCancel={onCancel}
            title="Create campaign"
            onConfirm={handleSubmit}
            submitting={submitting}
            classes={{ actions: classes.actions }}
            confirmText="Create">
            <TypeChooser name="type" />

            <Input name="name" label="Campaign name" validate={required} />

            <div className={classes.inputsContainer}>
              <Input name="numberOfCodes" label="Number of codes" validate={required} />
              <Input
                name="priceOfCodes"
                label="Amount"
                validate={required}
                classes={{ input: classes.input }}
                inputProps={{
                  InputProps: {
                    startAdornment: values.type === CreditCodeType.Fund ? <span>$</span> : '',
                  },
                }}
              />
              <Input name="redeemLimitOfCodes" label="Usage limit per code" validate={required} />
            </div>

            <div className={classes.date}>
              Start date
              <div className={classes.datePickerContainer}>
                <DateInputField name="startAtDate.date" validate={required} label="Select date" />
                <TimeInputField name="startAtDate.time" validate={required} label="Select time" />
              </div>
            </div>

            <div className={classes.date}>
              End date
              <div className={classes.datePickerContainer}>
                <DateInputField name="endAtDate.date" validate={required} label="Select date" />
                <TimeInputField name="endAtDate.time" validate={required} label="Select time" />
              </div>
            </div>
          </Dialog>
        );
      }}
    />
  );
};

export default CreateCampaignDialog;
