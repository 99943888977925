import { get } from 'lodash';
import { stringify } from 'query-string';

const resources = {
  users: 'users',
  userBalance: 'users/{id}/billing/balance',
  userHistory: 'users/{filter._id}/billing/history',
  balanceChange: 'users/{id}/billing/balance/{command}',
  shipments: 'shipments',
  enableUser: 'users/enable',
  disableUser: 'users/disable',
  confirmUser: 'users/confirm',
  resetUserPassword: 'users/reset-password',
  massResetUserPassword: 'users/reset-password/job ',
  balanceRefund: 'users/{id}/billing/refund',
  exportDebtors: 'debtors/export',
  exportDebtorsStatus: 'debtors/export/status',
  debtors: 'debtors',
  debtorsLoss: 'debtors/loss/{id}',
  pushNotifications: 'push/broadcast',
  debtorsStatuses: 'debtors/statuses',
  unblockFraud: 'fraudsters/unblock',
  bypassFraud: 'fraudsters/bypass',
  fraudDetails: `fraudsters`,
  blocklist: 'users/blocklist/phones',
  additionalDocument: `{type}/{id}/documents/{code}`,
  fraudDashboard: `users`,
  sharedWarehouses: `fraudsters/grouped-shared-warehouse-addresses`,
  reviewSharedWarehouse: `fraudsters/review-company-location-presets`,
  fraudTypes: `fraudsters/dictionary/fraud-types`,
  userActivity: `audit/list`,
  creditCodes: `credit-codes/campaigns`,
  claims: `claim`,
  userActivityTypes: `audit/dictionary/audit-actions`,
  stripeReceipt: `users/{_id}/billing/receipt`,
};

const getResource = (resource, params: any = null, isExternal?) => {
  if (isExternal) {
    return params?.data || params?.filter ? stringify(params?.data || params?.filter) : '';
  }

  return resources[resource]
    ? params
      ? resources[resource].replace(
          /{([\w.]+)}/g,
          (match, key) => get(params, key) || params.data[key]
        )
      : resources[resource]
    : resource;
};

export default getResource;
